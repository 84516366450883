/* Hide scrollbars for WebKit browsers (Chrome, Safari) */
*::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Hide scrollbar track and thumb for WebKit browsers */
*::-webkit-scrollbar-track,
*::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Hide scrollbar buttons (arrows) for WebKit browsers */
*::-webkit-scrollbar-button {
  display: none;
}

/* Hide scrollbars for Firefox */
* {
  scrollbar-width: none;
  /* Hides the scrollbar for Firefox */
}

/* Ensures overflow is still functional for both directions */

/* General background color for HTML */
html {
  background-color: #0a0118;
}

body {
  pointer-events: auto !important;
  overscroll-behavior-y: none;
}

.con-mid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.no-gap {
  margin: 0;
}

.MuiTimePickerToolbar-ampmLabel {
  color: rgba(255, 255, 255, 0.2) !important;
}

.MuiTimePickerToolbar-ampmSelection .Mui-selected {
  color: rgba(255, 255, 255, 1) !important;
}
